import React, { useState, useEffect, ReactNode } from "react"

import clsx from "clsx"

import SwiperCore, { Navigation } from "swiper"
import type { SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import Container from "@ecom/ui/components/Container"

import Card from "./Card"
import NavBtn from "./NavBtn"

import fetchPartners from "./helpers/fetchPartners"
import { partnersMain } from "./helpers"

import * as styles from "./partners.module.scss"

SwiperCore.use([Navigation])

export type Partner = {
  id: number
  brand: string
  icon_url: string
  urlName: string
  period: number
}

type Props = {
  title?: ReactNode
  orderNum?: string
  additionalPartners?: Partner[]
}

const removedPartners = [
  "megamarket",
  "akusherstvo-ru",
  "korablik",
  "kofe-haus",
  "shokoladnica",
  "sbermarket",
]

export default function Partners({ title, orderNum, additionalPartners = partnersMain }: Props) {
  const [partners, setPartners] = useState<Partner[]>([...additionalPartners])

  const params: SwiperOptions = {
    navigation: {
      nextEl: `.${styles.btnNext}`,
      prevEl: `.${styles.btnPrev}`,
    },
    slidesPerView: "auto",
  }

  const removeDuplicatesPartners = (backPartners: Partner[]): Partner[] => {
    const partnerUrlNames = partners.map((partner) => partner.urlName)
    const partnerUrlNamesSet = new Set(partnerUrlNames)

    return backPartners.filter(
      (partner) =>
        !removedPartners.includes(partner.urlName) && !partnerUrlNamesSet.has(partner.urlName)
    )
  }

  function getPartners(offset?: number) {
    fetchPartners(offset).then((partners1) => {
      const filteredShops = removeDuplicatesPartners(partners1)
      setPartners((prev) => prev.concat(filteredShops))
    })
  }

  useEffect(() => {
    getPartners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onSlideNext(instance: SwiperCore) {
    const { realIndex, slides } = instance
    const partnersLen = slides.length

    if (realIndex >= partnersLen - 10) {
      getPartners(partnersLen)
    }
  }

  return (
    <section className={clsx(styles.root, "content-visibility-auto")} data-exclude={orderNum}>
      {title || <h2 className={styles.head}>Партнеры</h2>}
      <div className={styles.container}>
        <Container className={styles.swiperOuter}>
          <NavBtn className={styles.btnNext} />
          <NavBtn className={styles.btnPrev} />
          <Swiper {...params} onSlideNextTransitionEnd={onSlideNext} className={styles.swiper}>
            {partners.map((data) => (
              <SwiperSlide className={styles.slide} key={data.id}>
                <Card {...data} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div>
    </section>
  )
}
